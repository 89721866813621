import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, Subject } from 'rxjs';
import { LoggerService } from './logger.service';
import { DatabaseReference } from '@angular/fire/compat/database/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private connectedRef: DatabaseReference;
  private connected: boolean = true;
  private timeout: any;
  private online: Subject<boolean>;

  constructor(private db: AngularFireDatabase, private logger: LoggerService) {
    this.online = new Subject();
  }

  async listenForConnectionChanges() {
    if (this.connectedRef) {
      this.connectedRef.off('value');
    }
    this.connectedRef = this.db.database.ref('.info/connected');
    this.connectedRef.on('value', (snap: any) => {
      this.connected = snap.val();
      this.checkConnectedStatus();
    });
  }

  async checkConnectedStatus() {
    if (!this.connected) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (!this.connected) {
          this.online.next(false);
          //  this.logger.showToast(this.tp.transform('FAILED_CONNECT'), 3000, 'top');
        }
      }, 2 * 1000);
    } else {
      this.online.next(true);
    }
  }

  subscribeConnectionStatus(): Observable<boolean> {
    return this.online;
  }

  getConnected(): boolean {
    return this.connected;
  }
}
