import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

export const authGuard = async (data: any) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const url = data?._routerState?.url || null;
  let user: any;
  try {
    user = await firstValueFrom(authService.currentUserObservable());
  } catch (error) {
    console.error(error);
  }
  if (user) {
    return true;
  }
  return router.parseUrl('login?continue=' + url);
};
