import { APP_INITIALIZER, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule, USE_EMULATOR as STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, USE_EMULATOR as FUNCTIONS_EMULATOR, REGION } from '@angular/fire/compat/functions';
import { initializeAuth, provideAuth } from '@angular/fire/auth';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateService } from './app/shared/translate/services/translate.service';
import { TranslateModule, setupTranslateFactory } from './app/shared/translate/translate.module';
import { HttpClientModule } from '@angular/common/http';
import { indexedDBLocalPersistence } from 'firebase/auth';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { MarkdownModule } from 'ngx-markdown';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
registerLocaleData(localeHu);

const isLocal = environment.id === 'local';

if (environment.id === 'prod') {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(
      IonicModule.forRoot({ mode: 'ios' }),
      IonicStorageModule.forRoot({}),
      HttpClientModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      // provideAuth(() => getAuth()),
      provideAuth(() => initializeAuth(getApp(), { persistence: indexedDBLocalPersistence })),
      provideMessaging(() => getMessaging()),
      provideAnalytics(() => getAnalytics()),
      provideRemoteConfig(() => getRemoteConfig()),
      providePerformance(() => getPerformance()),
      MarkdownModule.forRoot()
    ),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {
      provide: AUTH_EMULATOR,
      useValue: isLocal ? [`http://${environment.emulatorHost}:9099`] : null,
    },
    { provide: FIRESTORE_EMULATOR, useValue: isLocal ? [environment.emulatorHost, 8080] : null },
    { provide: STORAGE_EMULATOR, useValue: isLocal ? [environment.emulatorHost, 9199] : null },
    { provide: FUNCTIONS_EMULATOR, useValue: isLocal ? [environment.emulatorHost, 5003] : null },
    { provide: REGION, useValue: environment.region },
    provideRouter(routes),
    provideAnimations(),
    ScreenTrackingService,
    UserTrackingService,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    GooglePlus,
    SignInWithApple,
    TranslateModule,
    TranslateService,
    Deeplinks,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'hu-HU' },
  ],
});
