export const environment = {
  firebase: {
    apiKey: 'AIzaSyBD9OYNQ77odxSIMOjONt2m2SK4SZZUB6U',
    authDomain: 'transzlacios-pedagogia-prod.firebaseapp.com',
    projectId: 'transzlacios-pedagogia-prod',
    storageBucket: 'transzlacios-pedagogia-prod.appspot.com',
    messagingSenderId: '910036737972',
    appId: '1:910036737972:web:ee1819d02ef7170bc09ce1',
    measurementId: 'G-XZ570ZRFSE',
    databaseURL: 'https://transzlacios-pedagogia-prod-default-rtdb.europe-west1.firebasedatabase.app',
  },
  cfBase: 'https://europe-west1-transzlacios-pedagogia-prod.cloudfunctions.net/',
  production: true,
  id: 'prod',
  region: 'europe-west1',
  version: '0.2.17',
  buildNumber: '1290',
  emulatorHost: 'localhost',
  webClientId: '910036737972-u4giqdobhhk7hs390gq5bg9racv10s8u.apps.googleusercontent.com',
};
