<!-- <ion-header>
  <ion-toolbar class="ion-text-center">
    <ion-label>Neptun kód</ion-label>
  </ion-toolbar>
</ion-header> -->

<ion-content>
  <div style="margin: 16px; text-align: start; font-size: 16px; ">
    <ion-label>{{labelText}}</ion-label>
  </div>
  <ion-list>
    <ion-row style="width: 100%;">
      <ion-item [class.disabledButton]="loading" class="neptunItem">
        <ion-col class="ion-text-start" size="12">
          <ion-input maxlength="12" type="text" [(ngModel)]="neptunCode" [placeholder]="'NEPTUN_CODE'|t"></ion-input>
        </ion-col>
      </ion-item>
    </ion-row>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border modalFooter">
  <ion-button [class.disabledButton]="loading" (click)="changeModal('dismiss')" class="buttonM customButton"
    style="padding-right: 5px;" color="dark" slot="end" fill="clear" id="cancelButton">{{'CANCEL' |
    t}}</ion-button>
  <ion-button (click)="changeModal('confirm')" [disabled]="neptunCode.length<1" class="buttonM customButton"
    color="primary" slot="end" fill="clear" id="saveButton">
    <span [class.hideButton]="loading">{{'CONFIRM' | t}}</span>
    <ion-spinner style="position: absolute; margin-bottom: 3px;" *ngIf="loading" name="dots"
      color="primary"></ion-spinner>
  </ion-button>
</ion-footer>