import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 't',
  pure: false,
})
@Injectable({
  providedIn: 'root',
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(key: any): any {
    return this.translateService.translation[key] || key;
  }
}
