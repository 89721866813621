import { Component, EnvironmentInjector, inject, NgZone } from '@angular/core';
import { AlertController, IonicModule, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { CommonModule, Location } from '@angular/common';
import { TranslateService } from './shared/translate/services/translate.service';
import { TranslateModule } from './shared/translate/translate.module';
import { NetworkService } from './shared/services/network.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { LoggerService } from './shared/services/logger.service';
import { TranslatePipe } from './shared/translate/pipes/translate.pipe';
import { HeaderComponent } from './shared/components/header/header.component';
import { AuthService } from './shared/services/auth.service';
import { UserService } from './shared/services/user.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

// declare const ImpacTracking: any;
declare const universalLinks: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, HeaderComponent, TranslateModule],
})
export class AppComponent {
  networkStatus: boolean = true;
  networkSub: Subscription;
  firebaseUser: any;
  public environmentInjector = inject(EnvironmentInjector);
  constructor(
    private platform: Platform,
    private tr: TranslateService,
    private translate: TranslatePipe,
    private logger: LoggerService,
    private networkService: NetworkService,
    private toastController: ToastController,
    private authService: AuthService,
    private userService: UserService,
    private navController: NavController,
    private zone: NgZone,
    private modalController: ModalController,
    private alertController: AlertController,
    private deeplinks: Deeplinks,
    private location: Location
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.tr.use('hu');
      try {
        const user = await firstValueFrom(this.authService.currentUserObservable());
        if (user) {
          this.logger.setEmail(user.email);
          this.logger.setUserId(user.uid);
          this.userService.checkUserNeptunCode(user);
          // this.userService.checkUserFeedbackdata(user.uid);
        }
      } catch (error) {
        this.logger.handleError(error);
      }
      await this.networkService.checkConnectedStatus();
      await this.networkService.listenForConnectionChanges();
      // this.listenNetworkChanges();
      // if (this.platform.is('cordova') && this.platform.is('ios')) { setTimeout(() => { this.checkTracking(); }, 1000); }
      if (this.platform.is('cordova')) {
        this.initUniversalLinks();
        await this.handleBackButton();
      }
    });
  }

  async handleBackButton() {
    this.platform?.backButton?.subscribeWithPriority(10, async () => {
      const alert = await this.alertController?.getTop();
      const modal = await this.modalController?.getTop();
      if (modal || alert) {
        modal ? await modal?.dismiss() : null;
        alert ? await alert?.dismiss() : null;
      } else {
        this.location.back();
      }
    });
  }

  initUniversalLinks() {
    this.deeplinks
      .route({
        'x': 'x',
      })
      .subscribe(
        match => {
          const path = String(match.$link.host) || null;
          if (path) {
            this.zone.run(() => {
              this.navController.navigateRoot(path);
            });
          }
        },
        nomatch => { }
      );
    universalLinks.subscribe(
      'ul_appOpened',
      (link: any) => {
        const path = String(link.path) || null;
        if (path) {
          this.zone.run(() => {
            this.navController.navigateRoot(path);
          });
        }
      },
      (error: any) => {
        this.logger.handleError(error, true);
      }
    );
  }

  // checkTracking() {
  //   ImpacTracking.requestTracking(
  //     (success: any) => {
  //       console.log(success);
  //       if (success === true) {
  //         this.logger.setLogLevel('ONLINE');
  //       } else {
  //         this.logger.setLogLevel('NONE');
  //       }
  //     },
  //     (error: any) => {
  //       this.logger.handleError(error, true);
  //     }
  //   );
  // }

  listenNetworkChanges() {
    this.networkSub = this.networkService.subscribeConnectionStatus().subscribe(async (conn: any) => {
      this.networkStatus = conn;
      let hasFalseValue = false;
      if (this.networkStatus === false) {
        this.logger.showToast(this.translate.transform('NO_INTERNET'), 'infinite', 'bottom', 'dark', 'globe', true);
        this.logger.addLogItem('NO_INTERNET_CONNECTION');
        hasFalseValue = true;
      } else {
        const toast = await this.toastController?.getTop();
        if (toast) {
          toast.dismiss();
        }
      }
    });
  }

  ionViewDidLeave() {
    this.platform?.backButton?.unsubscribe();
  }
}
