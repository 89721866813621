export const EMAIL_REGEX = '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$';
export const PHONE_REGEX = /^(\+\d{1,3}[- ]?)?\d*$/;
export const POSTAL_CODE_REGEX = '^([1-9][0-9]{3})$';
export const TAJ_REGEX = '^([0-9]{3}-[0-9]{3}-[0-9]{3})$';
export const CAN_NOT_START_WITH_WHITE_SPACE = '^[^\\s].*';
export const WEBPAGE_REGEX = new RegExp(
  '(?:^|[ \\t])((https?:\\/\\/)?(?:localhost|[\\w-]+(?:\\.[\\w-]+)+)(:\\d+)?(\\/\\S*)?)'
);
export const TIME_REGEX = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
export const MAC_REGEX = '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$';
export const MAC_REGEX_SIMPLE = '^([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2}$';
export const MAC_2CHAR_REGEX = '^([0-9A-Fa-f]{2})$';
export const MAC_REGEX_SIMPLE_OR_COLON =
  '^(([0-9A-Fa-f]{12})|([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2}))$';
export const PW_STRENGTH = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{7,16})';
export const NUMBER_REGEX = '^[0-9]+$';
export const PHONE_NUMBER_REGEX = '^[+]{0,1}[0-9]+$';
export const IP_IPV4_REGEX =
  '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';
export const SIX_CHAR = '^[a-zA-Z0-9]{6,}$';
export const FOUR_DIGIT = '^[0-9]{4}$';
export const FOUR_EIGHT_DIGIT = '^[0-9]{4,8}$';
export const FOUR_SIX_DIGIT = '^[0-9]{4,6}$';
export const USERNAME = '^(?!.*[<>{}$,])^(?=.*[0-9a-zA-Z]).{3,36}$';
export const JOINCODE = '^[0-9]{6}$';
export const PERCENTAGE_1_100_AND_CHAR = '^(100|[1-9][0-9]?)%?$';
export const NUMBERS_0_100 = '^([1-9]?[0-9]{0,1}|100)$'
export const NUMBERS_1_100 = '^(100|[1-9][0-9]?)$'
export const NEPTUN_CODE = '^[a-zA-Z0-9]{6,}$';
