import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { GoogleAuthProvider, OAuthProvider } from '@angular/fire/auth';
import { LoadingController } from '@ionic/angular';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple, ASAuthorizationAppleIDRequest, AppleSignInResponse } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { environment } from 'src/environments/environment';
import 'firebase/auth';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private storage: StorageService,
    private loadingController: LoadingController,
    private googlePlus: GooglePlus,
    private apple: SignInWithApple
  ) { }

  async signInWithApplePlugin(): Promise<AppleSignInResponse> {
    return this.apple.signin({
      requestedScopes: [ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName, ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail],
    });
  }

  onAppleLoginSuccess(idToken: string) {
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({ idToken });
    return this.afAuth.signInWithCredential(credential);
  }

  async signInWithGooglePlugin() {
    let authData: any;
    let error: any;
    let params: any;
    params = {
      webClientId: environment.webClientId,
      offline: true,
    };
    try {
      await this.googlePlus.trySilentLogin({});
    } catch (error) { }
    try {
      await this.googlePlus.logout();
    } catch (error) { }
    try {
      await this.googlePlus.disconnect();
    } catch (error) { }
    try {
      authData = await this.googlePlus.login(params);
    } catch (e) {
      if (e === 12501) {
        error = 'GOOGLE_MODAL_CLOSED_WITHOUT_ACTION: ' + e;
      } else {
        error = 'GOOGLE_LOGIN_ERROR: ' + e;
      }
    }
    return { authData, error };
  }

  onGoogleLoginSuccess(idToken: any, accessToken: any) {
    const credential = accessToken ? GoogleAuthProvider.credential(idToken, accessToken) : GoogleAuthProvider.credential(idToken);
    return this.afAuth.signInWithCredential(credential);
  }

  getProviderFor(p: string): firebase.default.auth.AuthProvider | null {
    if (p === 'google') {
      return new GoogleAuthProvider();
    }
    if (p === 'apple') {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      return provider;
    }
    return null;
  }

  async signInWithPopup(provider: any): Promise<firebase.default.auth.UserCredential> {
    return this.afAuth.signInWithPopup(provider);
  }

  async signInWithRedirect(provider: any): Promise<void> {
    return this.afAuth.signInWithRedirect(provider);
  }

  async getRedirectResult(): Promise<any> {
    return this.afAuth.getRedirectResult();
  }

  async login(email: string, password: string): Promise<firebase.default.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async loginAnonymously() {
    return this.afAuth.signInAnonymously();
  }

  async fbLogout() {
    await this.afAuth.signOut();
  }

  async logout(text?: string) {
    if (text) {
      const loading = await this.loadingController.create({
        message: text,
      });
      await loading.present();
    }
    this.fbLogout()
      .then(async (data: any) => {
        /* let temp = localStorage.getItem('lang')!; */
        localStorage.clear();
        this.storage.clear();
        /*  localStorage.setItem('lang', temp); */
        if (text) {
          this.loadingController?.dismiss();
          this.router.navigate(['login']);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  authenticated(): boolean {
    return this.afAuth.authState !== null;
  }

  authState() {
    return this.afAuth.authState;
  }

  currentUserObservable() {
    return this.afAuth.authState;
  }

  currentUser() {
    this.afAuth.currentUser;
  }

  reAuth() {}

  getAFAuth() {
    return this.afAuth;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null ? true : false;
  }

  async newPassword(newPassword: string) {
    return this.afAuth.currentUser.then(user => user?.updatePassword(newPassword));
  }

  async newEmail(newEmail: string) {
    return this.afAuth.currentUser.then(user => user?.updateEmail(newEmail));
  }

  async updateCurrentUserName(name: string) {
    return this.afAuth.currentUser.then(user =>
      user?.updateProfile({
        displayName: name,
      })
    );
  }

  async passwordRemind(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async sendEmailVerification() {
    const user = await this.afAuth.currentUser;
    return user?.sendEmailVerification();
  }

  createUserWithEmailAndPassword(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  async createUser(email: string, password: string, name?: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password).then(result => {
      if (name) {
        this.updateCurrentUserName(name);
      }
      return result.user;
    });
  }
}
