export enum StorageItems {
  'USER' = 'user',
  'PROFILES' = 'profiles',
  'SELECTED_INDEX' = 'selectedProfileIndex',
  'REMEMBER_ME' = 'isRememberMe',
  'LANGUAGE' = 'lang',
  'PROFILE' = 'profile',
  'QUEST' = 'questionnaire',
  'USERID' = 'userId',
  'USERNAME' = 'username',
  'RESPONSEID' = 'responseId',
  'PARTICIPANTID' = 'participantId',
  'ANONYMUSER' = 'anonymUser'

}
